import { fontFamily, fontWeight } from "@assets/style/font-styles";
import { FloatingLabelInput } from "@components/FloatingLabelInput";
import Icon from "@components/Icon";
import Input from "@components/Input";
import { TextArea } from "@components/TextArea";
import Typography from "@components/Typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BORDERRADIUS_3 } from "@variables/border";
import colors from "@variables/colors";
import { FONTSIZE_1, FONTSIZE_3, FONTSIZE_5 } from "@variables/font-sizes";
import { mdLayout } from "@variables/layout";
import { MARGIN_10, MARGIN_9 } from "@variables/margins";
import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";

const vaildateEmail = (email: string) => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return regex.test(email);
};

export default function ContactForm() {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [emailError, setEmailError] = useState("");

  function handleChange(e: any) {
    if (e.target.files) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError("");

    if (!vaildateEmail(data.email)) {
      setEmailError("email is not valid");
      return;
    }

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData as any).toString(),
    }).then(() => {
      setData({ name: "", email: "", phone: "", message: "" });
      Swal.fire({
        icon: "success",
        title: "Thanks for your message",
        text: "We will back to you soon",
        showConfirmButton: false,
        timer: 1500,
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit} data-netlify="true" name="contact">
      <Title as="h2" content="Let's connect" />
      <Description as="p" content="Give yourself a minute to contact with us" />

      <Wrapper>
        <input type={"hidden"} name="form-name" value="contact" />
        <FormRow>
          <label>
            <Icon name="accountOutline" />
          </label>
          <FloatingLabelInput
            withborder={true}
            value={data.name}
            onChange={handleChange}
            label="Full Name"
            size="small"
            name="name"
          />
        </FormRow>

        <FormRow>
          <label>
            <Icon name="EmailIcon" />
          </label>
          <div
            css={css`
              width: 100%;
            `}
          >
            <FloatingLabelInput
              withborder={true}
              value={data.email}
              onChange={handleChange}
              label="Email"
              size="small"
              name="email"
            />
            <Error>{emailError}</Error>
          </div>
        </FormRow>
        <FormRow>
          <label>
            <Icon name="PhoneIcon" />
          </label>
          <FloatingLabelInput
            withborder={true}
            value={data.phone}
            onChange={handleChange}
            label="Phone Number"
            size="small"
            name="phone"
          />
        </FormRow>

        <FormRow>
          <label>
            <Icon name="message" />
          </label>
          <TextArea
            name="message"
            rows={11}
            label="Message"
            size="small"
            value={data.message}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow
          css={css`
            margin: 0;
          `}
        >
          <label>
            <Icon name="" />
          </label>
          <Button id="contact-form-submit-button">Submit</Button>
        </FormRow>
      </Wrapper>
    </Form>
  );
}

const Form = styled.form`
  font-family: ${fontFamily("Montserrat")};
  background: ${colors.white};
  width: 50%;
  border: 1px solid #cecece;
  border-radius: ${BORDERRADIUS_3};
  padding: 1.5em;
  margin: ${MARGIN_10} auto;
  max-width: 800px;
  ${FONTSIZE_3}
  @media ${mdLayout} {
    width: 80%;
  }
`;

const Wrapper = styled.div`
  padding: 0.5em;
`;

const FormRow = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1.5em;
  color: ${colors.gray[600]};
  .icon {
    margin: 0.8em 0;
    width: 24px;
  }
`;

const Button = styled.button`
  height: fit-content;
  gap: 0.63rem;
  background: ${colors.mintGreen[400]};
  color: rgb(255, 255, 255);
  border: 1px solid ${colors.mintGreen[400]};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  ${FONTSIZE_1}
  font-weight: 500;
  text-transform: capitalize;
  padding: 1em;
  border-radius: 0.5em;
  flex: 1 1 0%;
  margin: auto;
  width: 100%;
`;

const Title = styled(Typography)`
  ${FONTSIZE_5}
  font-weight: ${fontWeight("bold")};
  color: hsla(224, 21%, 30%, 1);
  margin: auto;
  text-align: center;
`;
const Description = styled(Typography)`
  ${FONTSIZE_3}
  color: hsla(224, 21%, 30%, 1);
  padding: 0.5em 0 1.6em;
  max-width: 45ch;
  margin: auto;
  text-align: center;
`;

const Error = styled.div`
  color: ${colors.red};
  ${FONTSIZE_1};
  padding-top: 0.3em;
`;
