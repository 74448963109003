import React from "react";
import Layout from "../components/Layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import SocialMedia from "../components/SocialMedia";
import data from "../data/contact.json";
import SEO from "../components/SEO";
import metaData from "../data/metaData.json";
import HeroSection from "../components/HeroSection";
import Map from "../components/Map";
import { container, visually_hidden } from "../assets/style/GeneralStyleComponent";
import ContactCard from "../components/contact/ContactCard";
import colors from "@variables/colors";
import { MARGIN_10 } from "@variables/margins";
import { mdLayout } from "@variables/layout";
import {  PADDING_6, PADDING_7, PADDING_9 } from "@variables/paddings";
import { fontFamily, fontWeight } from "src/assets/style/font-styles";
import { FONTSIZE_9 } from "@variables/font-sizes";
import { PopupButton } from "@typeform/embed-react";
import ContactForm from "@components/contact/ContactForm";

const ContactPage = () => {
  return (
    <Layout>
      <SEO {...metaData.contact} heroImage={data.heroSection.image} />

      <HeroSection
        css={hero_section_style}
        title={data.heroSection.title}
        description={data.heroSection.description}
        imgAlt={data.heroSection.imageTitle}
        imgSrc={data.heroSection.image}
      />

      <h2 id="contact-form" css={visually_hidden}>
        Contact Form
      </h2>
      <ContactForm />

      <Map
        css={map_style}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d401.56487089692286!2d20.999248452513392!3d52.237320699562666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc885b89a17f%3A0x96d2122a36cecd27!2sGrzybowska%2022%2C%2000-131%20Warszawa%2C%20Poland!5e0!3m2!1sen!2seg!4v1654717733668!5m2!1sen!2seg"
      />
      <ContactDetails>
        {data.contactDetails.map((data, index) => (
          <ContactCard key={`contact-details-${index}`} data={data} />
        ))}
      </ContactDetails>

      <SocialMediaContainer content={data.socialMedia} size={"large"} />

    </Layout>
  );
};

export default ContactPage;

const ContactDetails = styled.div`
  ${container}
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 120px;
  padding: ${PADDING_9} 0 ${PADDING_6};
  align-items: stretch;

  @media ${mdLayout} {
    flex-direction: column;
    gap: 75px;
  }
`;

const SocialMediaContainer = styled(SocialMedia)`
  padding-bottom: ${PADDING_9};
  margin: 0 auto;
`;

const button_style = css`
  margin: ${MARGIN_10} auto;
  .popup-form-btn {
    ${FONTSIZE_9}
    color: ${colors.mintGreen[400]};
    font-family: ${fontFamily("Futura-PT")};
    font-weight: ${fontWeight("semibold")};
    background-color: transparent;
  }
`;

const map_style = css`
  ${container};
  height: 70vh;
`;

const hero_section_style = css`
  background: ${colors.mintGreen[200]};
  .hero-section__title {
  }
  .hero-section__description {
    max-width: 38ch;
  }
  .hero-section__image {
    width: 40%;
  }
`;
