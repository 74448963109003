import * as React from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface Props {
  className?: string;
  phrases: string | string[];
}

const MultiPhrasesParagraph = (props: Props): ReactJSXElement => {
  const { phrases, ...rest } = props;

  const getPhrases = () => {
    if (typeof phrases === "string") {
      return phrases;
    }

    return phrases.reduce((acc, phrase, index) => {
      acc.push(phrase);
      if (index < phrases.length - 1) {
        acc.push(<br />);
      }

      return acc;
    }, [] as (string | ReactJSXElement)[]);
  };

  return <p {...rest}>{getPhrases()}</p>;
};

export default MultiPhrasesParagraph;
