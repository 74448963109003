import styled from "@emotion/styled";
import { HTMLAttributes } from 'react';
import { CSSProp } from '@emotion/core';
import { fluid_media } from "../assets/style/GeneralStyleComponent";

interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  src: string;
  css?: CSSProp
}

const Map = ({src, ...otherProps}) => {
  return (
    <Container {...otherProps}>
      <iframe
        src={src}
        loading="lazy"
      ></iframe>
    </Container>
  );
};

export default Map;

const Container = styled.section`
  iframe {
      ${fluid_media}
  }
`;
