import React from "react";
import MultiPhrasesParagraph from "../MultiPhrasesParagraph";
import Typography from "../Typography";
import styled from "@emotion/styled";
import Icon from "../Icon";
import colors from "@variables/colors";
import { PADDING_4, PADDING_5 } from "@variables/paddings";
import { FONTSIZE_12, FONTSIZE_6 } from "@variables/font-sizes";
import { mdLayout } from "@variables/layout";
import { BORDERRADIUS_3, BORDERWIDTH_1 } from "@variables/border";

import { fontFamily, fontWeight } from "../../assets/style/font-styles";

const ContactCard = ({ data }) => {
  return (
    <Card>
      <div className="card__icon">
        <Icon name={data.icon} size="meduim" />
      </div>

      <Typography as="h2" content={data.title} className="card__title" />

      <div className="card__details">
        {data.type == "phone" && (
          <a href={`tel:${data.details}`}>{data.details}</a>
        )}
        {data.type == "email" && (
          <a href={`mailto:${data.details}`}>{data.details}</a>
        )}

        {data.type == "address" && (
          <MultiPhrasesParagraph phrases={data.details} />
        )}
      </div>
    </Card>
  );
};

export default ContactCard;

const Card = styled.div`
  border: ${BORDERWIDTH_1} solid ${colors.gray[200]};
  border-radius: ${BORDERRADIUS_3};
  flex: 1;
  flex-wrap: wrap;
  color: ${colors.gray[200]};
  text-align: center;
  padding: ${PADDING_5};
  position: relative;
  .card__icon {
    ${FONTSIZE_12}
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 115px;
    bottom: 80%;
    color: ${colors.mintGreen[400]};
    background-color: ${colors.white};
  }
  .card__details {
    display: block;
    padding: ${PADDING_4} 0 ${PADDING_5};
    font-family: ${fontFamily("Montserrat")};
    a {
      font-weight: ${fontWeight("medium")};
      &:hover {
        color: ${colors.mintGreen[400]};
      }
    }
  }
  .card__title {
    ${FONTSIZE_6}
    font-weight: ${fontWeight("regular")};
    padding-top: ${PADDING_4};
  }

  @media ${mdLayout} {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
`;
