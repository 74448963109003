import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import colors from "@variables/colors";
import { FONTSIZE_1, FONTSIZE_2, FONTSIZE_3 } from "@variables/font-sizes";
import { fontFamily } from "@assets/style/font-styles";

type size = "small" | "normal" | "large";

const inputSizes: { [key in size]: string } = {
  small: FONTSIZE_1,
  normal: FONTSIZE_2,
  large: FONTSIZE_3,
};

interface Props {
  id?: any;
  label?: any;
  onBlur?: any;
  onFocus?: any;
  type?: any;
  refs?: any;
  className?: any;
  value?: any;
  size?: any;
  withborder?: boolean;
  onChange?: any;
  onKeyDown?: any;
  disabled?: boolean;
  name?: string;
}

const FloatingLabelInput = ({
  id,
  label,
  value,
  onBlur,
  onFocus,
  type,
  refs,
  className,
  size,
  onChange,
  withborder = false,
  disabled,
  name,
  ...otherProps
}: Props) => {
  const [active, setActive] = useState(value && value.length > 0);
  const [_value, setValue] = useState(value);

  const ref = useRef(refs);

  const handleClick = () => {
    ref.current.focus();
  };

  useEffect(() => {
    setValue(value);
    console.log(value);
    setActive(value && value.length !== 0);
  }, [value]);

  const _onFocus = (event: any) => {
    setActive(true);
    if (onFocus && !disabled) {
      onFocus(event);
    }
  };

  const _onBlur = (event: any) => {
    setActive(event.target.value.length !== 0);
    if (onBlur && !disabled) {
      onBlur(event);
    }
  };

  const handleChange = (e: any) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  return (
    <FloatingLabelInputContainer
      onClick={handleClick}
      className={className}
      withBorder={withborder}
      active={active}
      disabled={disabled}
      size={size}
    >
      <FloatingLabel
        className={className}
        htmlFor={id}
        active={active}
        size={size}
      >
        {label}
      </FloatingLabel>
      <FloatingInput
        name={name}
        active={active}
        className={className}
        id={id}
        onBlur={_onBlur}
        onFocus={_onFocus}
        ref={ref}
        type={type}
        onChange={handleChange}
        value={_value}
        disabled={disabled}
        {...otherProps}
      />
    </FloatingLabelInputContainer>
  );
};

export default FloatingLabelInput;

const FloatingLabelInputContainer = styled.div<{
  withBorder?: boolean;
  active?: boolean;
  disabled?: boolean;
  size?: size;
}>`
  width: 100%;
  ${(props) => inputSizes[props.size || "normal"]};
  cursor: pointer;
  border: ${(props) =>
    props.withBorder ? `1px solid ${colors.gray[600]}` : "none"};
  position: ${(props) => props.withBorder && "relative"};
  padding: ${(props) => props.withBorder && "1em"};
  border-radius: 0.5em;
  border-color: ${(props) =>
    props.withBorder && props.active
      ? colors.mintGreen[400]
      : colors.gray[600]};
  background: ${(props) => props.disabled && colors.gray[100]};
`;

const FloatingLabel = styled.label<{ active?: boolean; size?: size }>`
  line-height: 1;
  display: block;
  padding: 2px;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${(props) =>
    props.active ? colors.mintGreen[400] : colors.gray[600]};
  background: ${(props) =>
    props.active ? colors.white : "transparent"} !important;
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  cursor: text;
  pointer-events: none;
  width: 66.6%;
  font-family: ${fontFamily("montserrat")};
  top: ${(props) => (props.active ? "-0.6em" : "1.1em")};
  width: fit-content !important;
  height: fit-content;
`;

const FloatingInput = styled.input<{
  active?: boolean;
  size?: size;
}>`
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: ${colors.black};
  background: transparent;
  line-height: 1;

  &::placeholder {
    color: ${colors.black};
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
